import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InputService } from '../input/input.service';

import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'st-textarea',
  template: `<div
    [class]="'st-textarea ' + class"
    [ngClass]="{
      'st-textarea--invalid': isInvalid,
      'st-textarea--disabled': disabled
    }">
    <div *ngIf="label" class="st-textarea__label">
      {{ label }}<span *ngIf="isRequired">*</span>
    </div>

    <div *ngIf="header" class="st-textarea__label-header">
      {{ header }}<span *ngIf="isRequired">*</span>
    </div>

    <textarea
      [name]="name"
      [ngStyle]="{ width: width, height: height }"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [formControl]="inputControl">
      {{ value }}
      </textarea
    >

    <div class="st-textarea__error" *ngIf="isInvalid">
      {{ validationErrorMessage }}
    </div>
  </div> `,

  styles: [
    `
      @import 'variables';

      .st-textarea {
        textarea {
          @apply w-full mb-5;
          background: $white;
          border: 1px solid #d6d8d5;
          box-shadow:
            0 0 1px rgba(90, 89, 82, 0.15),
            0 1px 4px rgba(90, 89, 82, 0.15);
          border-radius: 12px;
          padding: 16px;

          [placeholder] {
            color: $base-gray-70;
          }
        }

        &__label {
          @apply ml-2 text-sm;
        }

        &__label-header {
          @apply pb-2 font-book;
        }

        &__error {
          @include input-error;
        }

        &--invalid {
          textarea {
            border-color: $alert-100;
          }
        }

        &--disabled {
          textarea {
            @include input-disabled;
          }
        }
      }
    `,
  ],
})
export class StTextareaComponent {
  @Input() width: string = '100%';
  @Input() height: string = '120px';
  @Input() name: string = 'st-textarea';
  @Input() label: string;
  @Input() header: string;
  @Input() disabled = false;
  @Input() value = '';
  @Input() placeholder: string = '';
  @Input() class: string = '';

  @Input() inputControl = new FormControl('');

  constructor(private inputService: InputService) {}

  get isInvalid(): boolean {
    const isTouched = this.inputControl.touched || this.inputControl.dirty;
    return this.inputControl.status === 'INVALID' && isTouched;
  }

  get isRequired(): boolean {
    return this.inputControl.hasValidator(Validators.required);
  }

  get validationErrorMessage(): string {
    return this.inputService.getValidationErrorMessage(this.inputControl);
  }
}
