import { Component, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '@app/components/modal/modal.component';
import { AlertComponent } from '@app/components/alert/alert.component';

import { FormControl, Validators } from '@angular/forms';
import { StTextareaComponent } from '@app/components/st-textarea/st-textarea.component';

@Component({
  selector: 'st-support-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent, AlertComponent, StTextareaComponent],
  template: `
    <st-modal
      [title]="'How can we help you?'"
      [closeOnEsc]="true"
      [iconName]="'ico-warning-2'"
      [discardNextBtn]="true"
      [loading]="loading"
      [nextText]="'Send Request'"
      (next)="onSubmit()"
      (hidden)="onHidden()">
      <p>
        Let us know what you need, and one of our team members will get back to
        you shortly.
      </p>
      <st-textarea
        [inputControl]="message"
        class="my-8"
        header="Support request"
        placeholder="Write here"
        name="message" />

      <st-alert *ngIf="apiError" [apiError]="apiError" class="mb-6" />
    </st-modal>
  `,
  styles: [],
})
export class SupportModalComponent {
  @Output() hidden = new EventEmitter<void>();

  message = new FormControl('', Validators.required);

  showSupportModal = true;
  apiError: any;
  loading: boolean = false;

  onHidden() {
    this.showSupportModal = false;
    this.hidden.emit();
  }

  async onSubmit() {
    const message = this.message.value;

    this.message.markAsTouched();

    if (this.message.invalid) {
      return;
    }

    try {
      this.loading = true;

      throw new Error('Not implemented');
      // send message
    } catch (e) {
      this.apiError = e;
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
