import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'st-landing',
  template: `
    <main class="container mx-auto">
      <st-heroes
        heroName="Thomas"
        heroDescription="learning English for 10 months" />

      <div class="aspect-w-16 aspect-h-9">
        <iframe
          src="https://www.youtube.com/embed/Rc4jzrRaoOE?si=t4Z26KsJ3ZpE62DA"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>

      <st-languages />
      <st-how-it-works />
      <st-why-speaktok />
      <!--  <st-trial-lesson></st-trial-lesson>-->
      <st-voice-of-our-learners />
      <st-billing-plans />
    </main>
    <footer class="container mx-auto">
      <st-footer-menu />
    </footer>
  `,
  styles: [
    `
      body {
        background: #e5e5e5;
      }
    `,
  ],
})
export class LandingComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // init
  }
}
