import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  constructor() {
    window.addEventListener('resize', this.onResize.bind(this));
    this.currentBreakpoint = this.getCurrentBreakpoint();
  }

  currentBreakpoint: string = '';
  resizeTimeout: any;

  getCurrentBreakpoint(): string {
    const breakpoints = {
      xs: 640, // to 639
      sm: 960,
      md: 1024,
      lg: 1366,
      xl: 5000,
    };
    const width = window.innerWidth;
    const breakpoint =
      Object.keys(breakpoints).find(key => width < breakpoints[key]) || 'xl';
    return breakpoint;
  }

  onResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.currentBreakpoint = this.getCurrentBreakpoint();
    }, 100);
  }

  isMobile() {
    return ['xs', 'sm'].includes(this.currentBreakpoint);
  }

  scrollToView(className: string, options: any = {}) {
    const { delay = 0, block = 'center' } = options || {};

    const element = document.querySelector(`.${className}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block });
      }, delay);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
