import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';

/**
 * Flowbite is a design system for Tailwind CSS that allows you to build professional designs in minutes.
 * @see https://flowbite.com/docs/getting-started/angular/
 */
import { initFlowbite } from 'flowbite';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'st-root',
  template: `
    <st-auth-wall></st-auth-wall>
    <router-outlet></router-outlet>
  `,
  styles: [``],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  get isLogged(): boolean {
    return this.authService.loggedInUser.value !== null;
  }

  ngOnInit(): void {
    this.authService.autoLogin();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const { navigationTrigger: trigger, url } = event;
        // console.log('NavigationStart', trigger, event);
        if (trigger === 'popstate' && url === '/' && this.isLogged) {
          this.router.navigate(['/dashboard']);
        }
      }
      if (event instanceof NavigationEnd) {
        // console.log('NavigationEnd', event);
        setTimeout(() => {
          initFlowbite();
        });
      }
    });
  }
}
