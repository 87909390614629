import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewEncapsulation,
  EventEmitter,
  OnChanges,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { BreakpointsService } from '@app/breakpoints.service';
import { StButtonComponent } from '../st-button/st-button.component';

@Component({
  selector: 'st-modal',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, StButtonComponent],
  template: `
    <div
      *ngIf="active"
      class="st-modal"
      [ngClass]="[
        'st-modal--v-' + variant,
        mobileCompact ? 'st-modal--compact' : ''
      ]">
      <div
        *ngIf="!isFullscreen"
        class="st-modal__backdrop"
        (click)="onBackdropClicked()"></div>
      <div
        class="st-modal__wrapper"
        [ngClass]="['st-modal--v-' + variant, getWrapperClass]">
        <div
          class="st-modal__content"
          [ngClass]="[contentClass, getContentClass]">
          <div
            *ngIf="!isAlert && !isIcon"
            class="text-xl font-book mb-3  pr-10"
            [ngClass]="{ '-mt-12 ': closeBtn }">
            {{ title }}
          </div>

          <div
            *ngIf="closeBtn"
            class="st-modal__close-icon cursor-pointer"
            (click)="hide()">
            <svg-icon
              src="assets/svg/ico-close.svg"
              class=""
              [svgStyle]="{ 'width.px': 22 }" />
          </div>

          <div
            *ngIf="backBtn"
            class="st-modal__back flex items-center cursor-pointer"
            (click)="hide()">
            <svg-icon
              src="assets/svg/ico-left.svg"
              class=""
              [svgStyle]="{ 'width.px': 22 }" />
            <span class="font-book ml-2 text-sm mt-[2px]">Back</span>
          </div>

          <!-- alert variant -->
          <div *ngIf="isAlert" class="flex justify-center">
            <div class="text-green-500 mr-4">
              <svg-icon
                *ngIf="variant === 'alert-success'"
                src="assets/svg/ico-success.svg"
                class="text-success"
                [svgStyle]="{ 'width.px': 48 }" />
            </div>
            <div class="pt-1 md:pt-2 flex-grow">
              <div class="text-xl font-book mb-3">{{ title }}</div>
              <div *ngIf="description" class="">{{ description }}</div>
            </div>
          </div>

          <!-- icon variant -->
          <div *ngIf="isIcon" class="flex justify-center mt-0 mb-6">
            <svg-icon
              [src]="'assets/svg/' + iconName + '.svg'"
              [svgStyle]="{ 'width.px': isMobile ? 40 : 80 }" />
          </div>

          <div *ngIf="isIcon && title" class="text-2xl font-book mb-3">
            {{ title }}
          </div>

          <ng-content></ng-content>

          <div *ngIf="okBtn" class="flex justify-end mt-8">
            <st-button [text]="'Okay'" (buttonClicked)="hide()" />
          </div>

          <div *ngIf="discardNextBtn" class="md:flex justify-between mt-10">
            <st-button
              [text]="discardText"
              [style]="'link'"
              class="pl-0 invisible md:visible"
              (clicked)="hide()" />

            <st-button
              [block]="true"
              [text]="nextText"
              [disabled]="loading"
              (clicked)="emitNext()"
              class="" />
          </div>
        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      @import 'variables';
      body.modal-open {
        overflow: hidden;
      }

      .st-modal {
        @apply fixed top-0 left-0 w-full h-full z-50 sm:py-6 flex justify-center flex-wrap items-center;
        color: $primary;

        overflow: auto;

        &__backdrop {
          @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-50;
        }

        &__wrapper {
          background-color: $white;

          @apply relative pb-8 lg:pb-10;
          z-index: 1010;
          min-height: 100vh;

          box-shadow: 1px 2px 16px 0px rgba(90, 89, 82, 0.4);

          width: 100%;
          max-width: 550px;

          @screen sm {
            border-radius: 24px;
            min-height: auto;
          }
        }

        &__close-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          color: $primary;
        }

        &__back {
          position: absolute;
          top: 10px;
          cursor: pointer;
          color: $primary;
        }

        &--v-fullscreen {
          @apply h-full w-full m-0;
          min-height: 100vh;
          justify-content: flex-start;
          align-items: flex-start;

          &.st-modal {
            overflow: none;
            @apply p-0 m-0;

            &__wrapper {
              @apply pt-4 md:pt-8;
              width: 100%;
              height: 100%;
              border-radius: 0;
              max-width: 100%;

              overflow: auto;
              max-height: 100vh;
            }

            &__back {
              // @apply pl-4 sm:pl-0;
            }
          }
        }

        &--v-large {
          .st-modal__wrapper {
            max-width: 800px;
            height: auto;
          }
          .st-modal__close-icon {
            top: 30px;
            right: 30px;
          }
        }

        &--v-xl {
          .st-modal__wrapper {
            max-width: 1100px;
            height: auto;
          }
          .st-modal__close-icon {
            top: 30px;
            right: 30px;
          }
        }

        &--v-alert-success {
          .st-modal {
            &__close-icon {
              top: 15px;
              right: 20px;
            }
            &__wrapper {
              @apply m-3;
              border-radius: 24px;
              min-height: auto;
            }

            &__content {
              @apply p-0  md:p-3 mt-0 lg:min-w-[700px];
            }
          }
        }

        &--compact {
          .st-modal {
            &__wrapper {
              @apply m-3;
              border-radius: 24px;
              min-height: auto;
            }
          }
        }
      }
    `,
  ],
})
export class ModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isActive: boolean = true;
  @Input() closeOnBackdrop: boolean = false;
  @Input() closeOnEsc: boolean = false;
  @Input() closeBtn: boolean = false;
  @Input() backBtn: boolean = false;
  @Input() okBtn: boolean = false;
  @Input() discardNextBtn: boolean = false;
  @Input() discardText: string = 'Discard';
  @Input() nextText: string = 'Next';
  @Input() loading: boolean = false;

  @Input() contentClass: string = '';
  @Input() wrapperClass: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() iconName: string;

  @Input() closeAfter: number = 0; // 0 means never close

  @Input() variant:
    | 'default'
    | 'large'
    | 'xl'
    | 'fullscreen'
    | 'alert-success' = 'default';

  // mobileCompact: if true, modal will be displayed in compact mode on mobile (not fullscreen)
  @Input() mobileCompact: boolean = false;

  @Output() hidden: EventEmitter<void> = new EventEmitter();
  @Output() next: EventEmitter<void> = new EventEmitter();
  active: boolean = false;
  closeAfterTimeout: any;

  private _modalClass: string = 'modal-open';

  constructor(private breakpointsService: BreakpointsService) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.closeOnEsc) {
      this.hide();
    }
  }

  ngOnInit(): void {
    if (this.isActive) this.setActive(true);
  }

  ngOnChanges(): void {
    this.setActive(this.isActive);
  }

  ngOnDestroy(): void {
    this.closeAfterTimeout && clearTimeout(this.closeAfterTimeout);
    document.body.classList.remove(this._modalClass);
  }

  get isMobile() {
    return this.breakpointsService.isMobile();
  }

  get isFullscreen(): boolean {
    return this.variant === 'fullscreen';
  }

  get isAlert(): boolean {
    return this.variant.includes('alert');
  }

  get isIcon(): boolean {
    return this.iconName && this.iconName !== '';
  }

  get showTitle(): boolean {
    return this.title !== '' && ['default', 'large'].includes(this.variant);
  }

  get getContentClass(): string {
    const topPadding = this.backBtn || this.closeBtn ? 'pt-16' : '';
    const bottomPadding = !this.isAlert ? 'pb-20 md:pb-0' : '';
    return topPadding + ' ' + bottomPadding;
  }

  get getWrapperClass(): string {
    return this.wrapperClass !== '' ? this.wrapperClass : 'p-5 md:p-8 ';
  }

  setActive(active: boolean): void {
    this.active = active;

    if (active) {
      document.body.classList.add(this._modalClass);
      if (this.closeAfter > 0) {
        this.closeAfterTimeout = setTimeout(() => {
          this.hide();
        }, 1000 * this.closeAfter);
      }
    } else {
      this.closeAfterTimeout && clearTimeout(this.closeAfterTimeout);

      document.body.classList.remove(this._modalClass);
      this.hidden.emit();
    }
  }

  show(): void {
    this.setActive(true);
  }

  hide(): void {
    this.setActive(false);
  }

  emitNext(): void {
    this.next.emit();
  }

  onBackdropClicked(): void {
    if (this.closeOnBackdrop) {
      this.hide();
    }
  }
}
