import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';

import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputService } from './input.service';
import { Validators } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'st-input',
  template: `
    <div
      class="st-input {{ class }}"
      [ngClass]="{
        'st-input--invalid': isInvalid,
        'st-input--disabled': disabled,
        'st-input--password': type === 'password'
      }"
      [ngStyle]="{ width: width }">
      <label *ngIf="label" class="st-input__label">
        {{ label }}<span *ngIf="isRequired">*</span>
      </label>

      <div class="relative">
        <div
          class="st-input__password-icon cursor-pointer"
          *ngIf="type === 'password'"
          (click)="onShowPassword()">
          <svg-icon
            *ngIf="passwordVisible"
            src="assets/svg/ico-eye.svg"
            class="mx-auto"
            [svgStyle]="{ 'width.px': 24 }" />
          <svg-icon
            *ngIf="!passwordVisible"
            src="assets/svg/ico-eye-off.svg"
            class="mx-auto"
            [svgStyle]="{ 'width.px': 24 }" />
        </div>

        <input
          class="st-input__input"
          [ngClass]="{ 'st-input__input--suffix': isSuffix }"
          [type]="getInputType"
          [name]="name"
          [value]="value"
          [placeholder]="placeholder"
          (input)="onChange($event)"
          (blur)="passwordVisible = false"
          [autocomplete]="autocomplete"
          [formControl]="inputControl"
          (keydown.enter)="enterPressed.emit($event)" />
      </div>

      <div class="st-input__error" *ngIf="isInvalid">
        {{ validationErrorMessage }}
      </div>
    </div>
  `,
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() type = 'text';
  @Input() name = '';
  @Input() disabled = false;
  @Input() value = '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() error = true;
  @Input() errorMessage = '';
  @Input() width = '';
  @Input() autocomplete = 'on';
  @Input() class: string = '';
  @Input() inputControl = new FormControl('');
  @Output() enterPressed = new EventEmitter();
  @Output() inputText = new EventEmitter();

  constructor(private inputService: InputService) {}

  passwordVisible: Boolean = false;

  get isInvalid(): boolean {
    const isTouched = this.inputControl.touched || this.inputControl.dirty;
    return this.inputControl.status === 'INVALID' && isTouched;
  }

  get isRequired(): boolean {
    return this.inputControl.hasValidator(Validators.required);
  }

  get isSuffix(): boolean {
    return this.type === 'password';
  }

  get getInputType(): string {
    return this.passwordVisible ? 'text' : this.type;
  }

  get validationErrorMessage(): string {
    return this.inputService.getValidationErrorMessage(this.inputControl);
  }

  onShowPassword(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  onChange(event: any): void {
    // emit
    this.inputText.emit(this.inputControl.value);
  }
}
