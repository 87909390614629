import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class NavigationPathsService {
  private readonly ST_API_PATH = `${environment.apiUrl}/api`;
  private readonly ST_STRAPI_API_PATH = `${environment.strapiApiUrl}/api`;

  // auth related

  login(): string {
    return `${this.ST_API_PATH}/auth/login`;
  }

  logout(): string {
    return `${this.ST_API_PATH}/auth/logout`;
  }

  refreshToken(): string {
    return `${this.ST_API_PATH}/auth/refresh-token`;
  }
  createTeacherAccount(): string {
    return `${this.ST_API_PATH}/auth/signup/teacher`;
  }

  resendTeacherInvitation(): string {
    return `${this.ST_API_PATH}/auth/signup/teacher/resend-invitation`;
  }

  activateTeacherAccount(): string {
    return `${this.ST_API_PATH}/auth/activate-account/teacher`;
  }

  activateStudentAccount(): string {
    return `${this.ST_API_PATH}/auth/activate-account/student`;
  }

  signupStudent(): string {
    return `${this.ST_API_PATH}/auth/signup/student`;
  }

  changePasswordRequest(): string {
    return `${this.ST_API_PATH}/auth/change-password/request`;
  }

  changePassword(): string {
    return `${this.ST_API_PATH}/auth/change-password`;
  }

  goalsOfLearning(): string {
    return `${this.ST_API_PATH}/dictionaries/courses`;
  }

  studentAvailability(): string {
    return `${this.ST_API_PATH}/dictionaries/student-availability`;
  }

  teachers(): string {
    return `${this.ST_API_PATH}/teachers`;
  }

  clientToken(): string {
    return `${this.ST_API_PATH}/payments/client-token`;
  }

  payments(): string {
    return `${this.ST_API_PATH}/payments`;
  }

  paymentById(paymentId: string): string {
    return `${this.ST_API_PATH}/payments/${paymentId}`;
  }

  files(): string {
    return `${this.ST_API_PATH}/files`;
  }

  // dictionaries
  accentsDict(): string {
    return `${this.ST_API_PATH}/dictionaries/accents`;
  }

  coursesDict(): string {
    return `${this.ST_API_PATH}/dictionaries/courses`;
  }

  gendersDict(): string {
    return `${this.ST_API_PATH}/dictionaries/genders`;
  }
  interestsDict(): string {
    return `${this.ST_API_PATH}/dictionaries/interests`;
  }

  languagesDict(): string {
    return `${this.ST_API_PATH}/dictionaries/languages`;
  }

  personalitiesDict(): string {
    return `${this.ST_API_PATH}/dictionaries/personalities`;
  }

  profilePersonalitiesDict(): string {
    return `${this.ST_API_PATH}/dictionaries/profile-personalities`;
  }

  teachingApproachesDict(): string {
    return `${this.ST_API_PATH}/dictionaries/teaching-approaches`;
  }

  proficiencyDict(): string {
    return `${this.ST_API_PATH}/dictionaries/proficiency`;
  }

  speakingLevelDict(): string {
    return `${this.ST_API_PATH}/dictionaries/speaking-levels`;
  }

  // lessons

  lessons(): string {
    return `${this.ST_API_PATH}/lessons`;
  }

  availableReschedules(): string {
    return `${this.lessons()}/available-reschedules`;
  }

  lessonById(id: string): string {
    return `${this.lessons()}/${id}`;
  }

  lessonReschedule(id: string): string {
    return `${this.lessonById(id)}/reschedule`;
  }

  lessonRescheduleToExtraTimeSlot(id: string): string {
    return `${this.lessonById(id)}/reschedule-to-extra-time-slot`;
  }

  lessonSchedule(id: string): string {
    return `${this.lessonById(id)}/schedule`;
  }

  lessonJoin(id: string): string {
    return `${this.lessonById(id)}/join`;
  }

  lessonLeave(id: string): string {
    return `${this.lessonById(id)}/leave`;
  }

  // wordbank
  wordBank(): string {
    return `${this.ST_API_PATH}/wordbank`;
  }

  wordBankWords(): string {
    return `${this.wordBank()}/words`;
  }

  wordBankWordId(wordId: string): string {
    return `${this.wordBank()}/words/${wordId}`;
  }

  // teacher profiles
  meTeacherProfile(): string {
    return `${this.ST_API_PATH}/teachers/me`;
  }

  mePersonalData(): string {
    return `${this.meTeacherProfile()}/personal-data`;
  }

  meMatchingData(): string {
    return `${this.meTeacherProfile()}/matching-data`;
  }

  meTeachingLanguages(): string {
    return `${this.meTeacherProfile()}/teaching-languages`;
  }

  meSpeakingLanguages(): string {
    return `${this.meTeacherProfile()}/speaking-languages`;
  }

  meTeachersCalendar(): string {
    return `${this.ST_API_PATH}/calendars/teachers/me`;
  }

  meTeacherStudents(): string {
    return `${this.meTeacherProfile()}/students`;
  }

  meTeacherAddTimeSlotAvailability(calendarId: string): string {
    return `${this.meTeachersCalendar()}/${calendarId}/time-slots`;
  }

  meTeacherDeleteTimeSlotAvailability(
    calendarId: string,
    timeSlotId: string
  ): string {
    return `${this.meTeachersCalendar()}/${calendarId}/time-slots/${timeSlotId}`;
  }

  meTeacherLockTimeSlot(timeSlotId: string): string {
    return `${this.meTeachersCalendar()}/time-slots/${timeSlotId}/lock`;
  }

  meTeacherUnlockTimeSlot(timeSlotId: string): string {
    return `${this.meTeachersCalendar()}/time-slots/${timeSlotId}/unlock`;
  }

  // admin teacher onboarding

  adminTeacherOnboarding(): string {
    return `${this.ST_API_PATH}/admin/teacher-onboarding`;
  }

  // student profile
  meStudentProfile(): string {
    return `${this.ST_API_PATH}/students/me`;
  }

  meStudentPersonalDetails(): string {
    return `${this.meStudentProfile()}/personal-details`;
  }

  meStudentBillToInfo(): string {
    return `${this.meStudentProfile()}/bill-to-info`;
  }

  meStudentActiveSubscriptions(): string {
    return `${this.meStudentProfile()}/active-subscriptions`;
  }

  meStudentTeachers(): string {
    return `${this.meStudentProfile()}/teachers`;
  }

  meStudentCalendar(): string {
    return `${this.meStudentProfile()}/calendar`;
  }

  meStudentBookCalendarTimeSlot(): string {
    return `${this.meStudentCalendar()}/time-slots/book`;
  }

  meStudentReleaseCalendarTimeSlot(): string {
    return `${this.meStudentCalendar()}/time-slots/release`;
  }

  meStudentLanguageLevels(): string {
    return `${this.meStudentProfile()}/language-levels`;
  }

  studentFeedback(): string {
    return `${this.ST_API_PATH}/feedbacks-from-student`;
  }

  // profile settings

  meProfileSettings(): string {
    return `${this.ST_API_PATH}/profile-settings/me`;
  }

  // dashboard (teacher) -> my availability

  teacherAvailability(): string {
    return `${this.ST_API_PATH}/teachers/me/availability`;
  }

  teacherAvailabilityTimeSlotAdd(): string {
    return `${this.teacherAvailability()}/time-slots`;
  }

  teacherAvailabilityTimeSlotDelete(timeSlotId: string): string {
    return `${this.teacherAvailability()}/time-slots/${timeSlotId}`;
  }

  teacherAvailabilityPublish(): string {
    return `${this.teacherAvailability()}/publish`;
  }

  teacherOnboardingMe(): string {
    return `${this.ST_API_PATH}/teacher-onboarding/me`;
  }

  // availability - onboarding - students view
  studentsTeacherAvailability(): string {
    return `${this.ST_API_PATH}/students/me/teacher-availability`;
  }

  studentsTeacherAvailabilityFind(tpid: string): string {
    return `${this.studentsTeacherAvailability()}?tpid=${tpid}`;
  }

  studentsTeacherAvailabilitySave(teacherAvailabilityId: string): string {
    return `${this.studentsTeacherAvailability()}/${teacherAvailabilityId}/time-slots`;
  }

  studentsTeacherAvailabilitySlots(
    teacherAvailabilityId: string,
    timeSlotId: string
  ): string {
    return `${this.studentsTeacherAvailability()}/${teacherAvailabilityId}/time-slots/${timeSlotId}`;
  }

  //
  personalSubscriptionId(id: string): string {
    return `${this.ST_API_PATH}/personal-subscriptions/${id}`;
  }

  // lesson templates - teacher view
  lessonTemplates(): string {
    return `${this.ST_API_PATH}/teachers/me/lesson-templates`;
  }

  lessonTemplate(id: string): string {
    return `${this.ST_API_PATH}/teachers/me/lesson-templates/${id}`;
  }

  lessonCourses(): string {
    return `${this.ST_API_PATH}/teachers/me/lesson-courses`;
  }

  lessonCourse(id: string): string {
    return `${this.ST_API_PATH}/teachers/me/lesson-courses/${id}`;
  }

  teacherFeedback(): string {
    return `${this.ST_API_PATH}/feedbacks-from-teacher`;
  }

  // chats
  chats(): string {
    return `${this.ST_API_PATH}/chats`;
  }

  chatMessages() {
    return `${this.ST_API_PATH}/messages`;
  }

  // public endpoints
  publicBillingPlans(): string {
    return `${this.ST_API_PATH}/public/billing-plans`;
  }

  publicCmsContentPage(query: string): string {
    return `${this.ST_STRAPI_API_PATH}/pages?${query}`;
  }

  publicCmsContentPost(query: string): string {
    return `${this.ST_STRAPI_API_PATH}/posts?${query}`;
  }

  publicCmsFaq(query: string): string {
    return `${this.ST_STRAPI_API_PATH}/faqs?${query}`;
  }

  publicTeacherProfile(): string {
    return `${this.ST_API_PATH}/teachers`;
  }

  publicTeacherProfileById(id: string): string {
    return `${this.ST_API_PATH}/teachers/${id}`;
  }

  // calendar
  private calendarsApi(): string {
    return `${this.ST_API_PATH}/calendars`;
  }

  teacherCalendars(): string {
    return this.calendarsApi() + '/teachers/me';
  }

  studentCalendars(): string {
    return this.calendarsApi() + '/students/me';
  }

  // trial calendar
  trialCalendars(): string {
    return `${this.ST_API_PATH}/trial-calendars/students/me`;
  }

  trialCalendarsBook(id: string): string {
    return this.trialCalendars() + `/time-slots/${id}/book`;
  }

  trialCalendarsRelease(id: string): string {
    return this.trialCalendars() + `/time-slots/${id}/release`;
  }

  trialCalendarsConfirm(id: string): string {
    return this.trialCalendars() + `/time-slots/${id}/confirm`;
  }

  // reschedule calendar
  rescheduleCalendars(): string {
    return `${this.ST_API_PATH}/reschedule-calendars/students/me`;
  }

  rescheduleCalendarsBook(id: string): string {
    return this.rescheduleCalendars() + `/time-slots/${id}/book`;
  }

  rescheduleCalendarsRelease(id: string): string {
    return this.rescheduleCalendars() + `/time-slots/${id}/release`;
  }

  rescheduleCalendarsConfirm(id: string): string {
    return this.rescheduleCalendars() + `/time-slots/${id}/confirm`;
  }
}
