import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '../avatar/avatar.component';

import { SupportModalComponent } from '@app/dashboard/components/support-modal/support-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule, AvatarComponent, SupportModalComponent],
  selector: 'st-dropdown-menu',
  template: `
    <div class="st-dropdown-menu">
      <div
        class="flex items-center cursor-pointer"
        (click)="toggleDropdownMenu()">
        <st-avatar src="{{ image }}" alt="{{ text }}" size="xmd" class="mr-2" />
        <div class="text -mb-[1px]">{{ text }}</div>
        <div class="chevron-down"></div>
      </div>

      <div class="st-dropdown-menu__menu" *ngIf="!hidden">
        <div
          class="st-dropdown-menu__menu-item"
          (click)="openProfileSettings()">
          Settings
        </div>
        <div class="st-dropdown-menu__menu-item" (click)="showSupportModal()">
          Support
        </div>

        <div class="st-dropdown-menu__menu-item" (click)="logout()">
          Log out
        </div>
      </div>
    </div>

    <st-support-modal *ngIf="supportModal" (hidden)="supportModal = false" />
  `,
  styleUrls: ['./st-dropdown-menu.component.scss'],
})
export class StDropdownMenuComponent implements OnInit, OnDestroy {
  private profileSettingsSub: Subscription;

  text = '';
  image = '';

  hidden: boolean = true;
  supportModal: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.profileSettingsSub = this.authService.settings.subscribe(settings => {
      this.text = settings?.fullName;
      this.image = settings?.avatarUrl;
    });

    // close on click outside
    document.addEventListener('click', event => this.onClickOutside(event));
  }

  ngOnDestroy(): void {
    if (this.profileSettingsSub) {
      this.profileSettingsSub.unsubscribe();
    }

    document.removeEventListener('click', event => this.onClickOutside(event));
  }

  toggleDropdownMenu() {
    this.hidden = !this.hidden;
  }

  openProfileSettings() {
    this.router.navigate(['/profile']);
  }

  showSupportModal() {
    this.supportModal = true;
    this.hidden = true;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  onClickOutside(event = null) {
    if (event && !(event.target as HTMLElement).closest('.st-dropdown-menu')) {
      this.hidden = true;
    }
  }
}
